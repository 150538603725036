import React, { useState } from "react"
import { StaticImage, GatsbyImageProps } from "gatsby-plugin-image";
import { ImageType } from "@/types";

const ImageElement: React.FC<ImageType> = ({ asset, alt  }) => {
  return (
    <>
    {asset === "checkin" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/Checkin-close-PS.png"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}
      {asset === "spark" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/Spark_reveal_500x500.gif"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {/*-------------LFA-------------------*/}
      {asset === "LFA4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/LFA/LFA-5.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}{asset === "LFA3" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/LFA/LFA-4.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        placeholder="blurred"
      />
    )}{asset === "LFA2" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/LFA/LFA-3.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        placeholder="blurred"
      />
    )}{asset === "LFA1" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/LFA/LFA-2.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        placeholder="blurred"
      />
    )}
      {asset === "LFA0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/LFA/LFA-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {/*-------------LFA-------------------*/}

      {/*-------------CUP-------------------*/}
      {asset === "CUP4" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/CUP/CUP-5.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        placeholder="blurred"
      />
    )}{asset === "CUP3" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/CUP/CUP-4.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        placeholder="blurred"
      />
    )}{asset === "CUP2" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/CUP/CUP-3.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        placeholder="blurred"
      />
    )}{asset === "CUP1" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/CUP/CUP-2.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        placeholder="blurred"
      />
    )}
      {asset === "CUP0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CUP/CUP-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {/*-------------CUP-------------------*/}

      {/*-------------------FWC1-------------------*/}
      {asset === "FWC13" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FWC1/FWC1-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "FWC12" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FWC1/FWC1-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "FWC11" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FWC1/FWC1-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "FWC10" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FWC1/FWC1-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {/*-------------------FWC1-------------------*/}


      {/*-------------------CLY-------------------*/}
      {asset === "CLY4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CLY/CLY-5.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "CLY3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CLY/CLY-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "CLY2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CLY/CLY-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "CLY1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CLY/CLY-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "CLY0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CLY/CLY-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {/*-------------------CLY-------------------*/}

      {/*-------------------OMB-------------------*/}
      {asset === "OMB3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/OMB/OMB-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "OMB2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/OMB/OMB-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "OMB1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/OMB/OMB-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "OMB0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/OMB/OMB-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {/*-------------------OMB-------------------*/}

      {/*-------------------FWC2-------------------*/}
      {asset === "FWC23" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FWC2/FWC2-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "FWC22" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FWC2/FWC2-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "FWC21" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FWC2/FWC2-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
      )}
      {asset === "FWC20" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FWC2/FWC2-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          placeholder="blurred"
        />
        )}
      {/*-------------------FWC2-------------------*/}

  </>
  )
};

export default ImageElement;
